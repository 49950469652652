// src/pages/HomePage.js

import React from 'react';
import Banner from '../components/Banner';
import ProductCard from '../components/ProductCard';
import '../styles/HomePage.css'; // Import the new CSS file

const HomePage = () => {
  return (
    <>
      <Banner
        image="/images/travel-mountains-outdoors.jpg"
        texts={["iSightSeeing", "Explore the beauty!"]}
        interval={6000}
      />
      <div className="homepage-wrapper">
        <div className="homepage-container">
          {/* USA Regions Section */}
          <div className="regions-section">
            <h2 className="regions-title">
              Explore Our Experiences Around the U.S.
            </h2>
            <div className="regions-grid">
              <ProductCard
                image="/images/regions/east-coast.jpeg"
                text="East Coast"
                url="usa/east-coast"
              />
              <ProductCard
                image="/images/regions/south.jpeg"
                text="South"
                url="usa/south"
              />
              <ProductCard
                image="/images/regions/midwest.jpeg"
                text="Midwest"
                url="usa/midwest"
              />
              <ProductCard
                image="/images/regions/mountain-west.jpeg"
                text="Mountain West"
                url="usa/mountain-west"
              />
              <ProductCard
                image="/images/regions/west-coast.jpeg"
                text="West Coast"
                url="usa/west-coast"
              />
              <ProductCard
                image="/images/regions/pacific-northwest.jpeg"
                text="Pacific Northwest"
                url="usa/pacific-northwest"
              />
              <ProductCard
                image="/images/regions/hawaii.jpeg"
                text="Hawaii"
                url="usa/hawaii"
              />
              <ProductCard
                image="/images/regions/alaska.jpeg"
                text="Alaska"
                url="usa/alaska"
              />
            </div>
          </div>
        </div>
        <div className="homepage-container">
          {/* Abroad Section */}
          <div className="regions-section">
            <h2 className="regions-title">
              Discover Our Experiences Abroad
            </h2>
            <div className="regions-grid">
              <ProductCard
                image="/images/banners/state-banners/france-banner.jpeg"
                text="Europe"
                url="abroad/europe"
              />
              <ProductCard
                image="/images/banners/state-banners/dubai-banner.jpeg"
                text="Middle East"
                url="abroad/middle-east"
              />
              <ProductCard
                image="/images/banners/state-banners/mexico-banner.jpeg"
                text="North America"
                url="abroad/north-america"
              />
              <ProductCard
                image="/images/banners/state-banners/brazil-banner.jpeg"
                text="South America"
                url="abroad/south-america"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
