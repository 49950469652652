// src/pages/ProductPage.js

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Banner from '../components/Banner';
import ProductCard from '../components/ProductCard';
import productData from '../data/productData';
import '../styles/ProductPage.css';

const ProductPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  // Function to find the item and collect ancestors based on path segments
  const findItemAndAncestors = (segments) => {
    let currentLevel = productData;
    const ancestors = [];
    let item = null;

    for (const segment of segments) {
      // Check if we're in "usa" or "abroad"
      if (currentLevel.usa && segment === 'usa') {
        item = currentLevel.usa;
        ancestors.push({ slug: segment, item });
        currentLevel = item;
      }
      else if (currentLevel.abroad && segment === 'abroad') {
        item = currentLevel.abroad;
        ancestors.push({ slug: segment, item });
        currentLevel = item;
      }
      // Check "regions"
      else if (currentLevel.regions && currentLevel.regions[segment]) {
        item = currentLevel.regions[segment];
        ancestors.push({ slug: segment, item });
        currentLevel = item;
      }
      // Check "states"
      else if (currentLevel.states && currentLevel.states[segment]) {
        item = currentLevel.states[segment];
        ancestors.push({ slug: segment, item });
        currentLevel = item;
      }
      // Check "cities"
      else if (currentLevel.cities && currentLevel.cities[segment]) {
        item = currentLevel.cities[segment];
        ancestors.push({ slug: segment, item });
        currentLevel = item;
      }
      // Check "attractions"
      else if (currentLevel.attractions && currentLevel.attractions[segment]) {
        item = currentLevel.attractions[segment];
        ancestors.push({ slug: segment, item });
        currentLevel = item;
      } else {
        // Not found
        return null;
      }
    }

    return { item, ancestors };
  };

  const result = findItemAndAncestors(pathSegments);

  // Extract necessary data from the result
  const currentItem = result?.item || null;
  const ancestors = result?.ancestors || [];
  const isProduct =
    currentItem &&
    !currentItem.states &&
    !currentItem.cities &&
    !currentItem.attractions;
  const isState =
    currentItem &&
    (currentItem.states || currentItem.cities || currentItem.attractions);

  // Unconditional useEffect to handle redirection
  useEffect(() => {
    if (currentItem && isState && !isProduct) {
      let firstAttractionPath = null;

      // Check for attractions directly under the state
      if (
        currentItem.attractions &&
        Object.keys(currentItem.attractions).length > 0
      ) {
        const firstAttractionKey = Object.keys(currentItem.attractions)[0];
        firstAttractionPath = `/${[...pathSegments, firstAttractionKey].join('/')}`;
      }
      // Check for cities with attractions
      else if (currentItem.cities) {
        for (const cityKey of Object.keys(currentItem.cities)) {
          const city = currentItem.cities[cityKey];
          if (city.attractions && Object.keys(city.attractions).length > 0) {
            const firstAttractionKey = Object.keys(city.attractions)[0];
            firstAttractionPath = `/${[
              ...pathSegments,
              cityKey,
              firstAttractionKey,
            ].join('/')}`;
            break;
          }
        }
      }

      if (firstAttractionPath) {
        navigate(firstAttractionPath);
      }
    }
  }, [currentItem, isState, isProduct, pathSegments, navigate]);

  // Early return if the result is not found
  if (!result) {
    return <div className="product-not-found">Product not found.</div>;
  }

  // Build breadcrumbs
  const breadcrumbs = [];
  let accumulatedSegments = [];
  ancestors.forEach(({ slug, item }) => {
    accumulatedSegments.push(slug);
    breadcrumbs.push({
      name: item.name,
      url: `/${accumulatedSegments.join('/')}`,
    });
  });

  // *** NEW LOGIC: find the correct "state-level" ancestor dynamically ***
  // Instead of "stateAncestorIndex = ancestors.length > 1 ? 1 : null;",
  // we find the last ancestor that actually has sub-items.
  let stateAncestor = null;
  for (let i = 0; i < ancestors.length; i++) {
    const a = ancestors[i];
    if (a.item.states || a.item.cities || a.item.attractions) {
      stateAncestor = a;
    }
  }

  // Collect all attractions under that "state-level" ancestor
  const collectAllAttractionsUnderState = (stateItem, pathSegments = []) => {
    let attractions = [];

    // Collect attractions directly under the state
    if (stateItem.attractions) {
      for (const [slug, attraction] of Object.entries(stateItem.attractions)) {
        attractions.push({
          item: attraction,
          path: [...pathSegments, slug],
        });
      }
    }

    // Collect attractions within cities
    if (stateItem.cities) {
      for (const [citySlug, cityItem] of Object.entries(stateItem.cities)) {
        const cityPath = [...pathSegments, citySlug];

        if (cityItem.attractions) {
          for (const [attractionSlug, attraction] of Object.entries(cityItem.attractions)) {
            attractions.push({
              item: attraction,
              path: [...cityPath, attractionSlug],
            });
          }
        }
      }
    }

    return attractions;
  };

  // Actually gather the sub-items now
  let attractionsUnderState = [];
  if (stateAncestor) {
    // We'll figure out which index in ancestors corresponds to stateAncestor,
    // and build a path array up to that index
    const foundIndex = ancestors.findIndex((a) => a === stateAncestor);
    const statePath = ancestors.slice(0, foundIndex + 1).map((a) => a.slug);

    attractionsUnderState = collectAllAttractionsUnderState(
      stateAncestor.item,
      statePath
    );
  }

  return (
    <>
      {/* Display the banner if available */}
      {currentItem?.bannerImage && (
        <Banner image={currentItem.bannerImage} texts={currentItem.name} />
      )}
      {!currentItem?.bannerImage && stateAncestor?.item.bannerImage && (
        <Banner
          image={stateAncestor.item.bannerImage}
          texts={stateAncestor.item.name}
        />
      )}

      {/* Breadcrumbs */}
      <nav className="breadcrumbs">
        {breadcrumbs.map((breadcrumb, index) => (
          <span key={breadcrumb.url}>
            <a href={breadcrumb.url} className="breadcrumb-link">
              {breadcrumb.name}
            </a>
            {index < breadcrumbs.length - 1 && ' / '}
          </span>
        ))}
      </nav>

      <div className="product-page-container">
        <div className="content-area">
          {isProduct ? (
            <>
              {/* Navigation between attractions under the same state */}
              {stateAncestor && attractionsUnderState.length > 1 && (
                <div className="navigation-container">
                  <div className="navigation-links">
                    {attractionsUnderState.map(({ item, path }) => {
                      const itemUrl = `/${path.join('/')}`;
                      const isActive = item === currentItem;
                      return (
                        <a
                          key={itemUrl}
                          href={itemUrl}
                          className={`navigation-link ${isActive ? 'active' : ''}`}
                        >
                          {item.name}
                        </a>
                      );
                    })}
                  </div>
                </div>
              )}

              {/* Product Description */}
              <div className="product-description">
                <div className="divider"></div>
                <h1 className="page-heading">{currentItem.title}</h1>
                <p className="product-description-text">
                  {currentItem.description}
                </p>
              </div>

              {/* Viator Widget */}
              <div className="viator-widget-container">
                <div
                  data-vi-partner-id="P00225563"
                  data-vi-widget-ref={currentItem.widgetRef}
                ></div>
              </div>
            </>
          ) : (
            <>
              {/* Category Page */}
              <h1 className="page-heading">Explore {currentItem.name}</h1>
              <div className="divider"></div>
              <div className="product-grid">
                {Object.entries(
                  currentItem.states ||
                  currentItem.cities ||
                  currentItem.attractions
                ).map(([slug, item]) => {
                  const itemUrl = `/${[...pathSegments, slug].join('/')}`;
                  return (
                    <ProductCard
                      key={itemUrl}
                      text={item.name}
                      image={item.bannerImage}
                      url={itemUrl}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductPage;
