// src/data/productData.js

/**
 * Data Structure Overview:

- The data is organized hierarchically to reflect the geographical hierarchy:
  - `regions`: Top-level regions (e.g., East Coast, South, Midwest).
  - Each `region` contains `states`.
  - Each `state` may contain `cities` or directly have `attractions`.
  - Each `city` contains `attractions`.

- URLs are constructed by joining the slugs of each level.

**When Adding New Locations:**
1. Add a new `region` if applicable, and place the new entry within the correct `states` or `cities`.
2. Ensure you provide all necessary keys like `name`, `slug`, `bannerImage`, etc.
3. The URL will be constructed as `/{regionSlug}/{stateSlug}/{citySlug}/{attractionSlug}`.

*/

const productData = {
  usa: {
    name: 'USA',
    regions: {
      //**************************************************************************************/
      //*******************         EAST COAST               *********************************/
      //**************************************************************************************/
      'east-coast': {
        name: 'East Coast',
        bannerImage: '/images/regions/east-coast.jpeg',
        states: {
          //********* NEW YORK *****************/
          'new-york': {
            name: 'New York',
            bannerImage: '/images/banners/state-banners/new-york-banner.jpeg',
            cities: {
              'nyc': {
                name: 'NYC',
                bannerImage: '/images/banners/location-banners/nyc-banner.jpeg',
                attractions: {
                  'times-square': {
                    name: 'Times Square',
                    title: 'Times Square Tours and Activities',
                    description: 'Discover the vibrant energy of Times Square.',
                    widgetRef: 'W-66824133-127a-4602-92ac-17f312733f12',
                  },
                  'statue-liberty': {
                    name: 'Statue of Liberty & Ellis Island',
                    title: 'Statue of Liberty & Ellis Island Tours',
                    description: 'Explore the iconic Statue of Liberty and Ellis Island.',
                    widgetRef: 'W-2ae7f2b4-ced4-4920-ba5b-c3f095b9ade4',
                  },
                  'central-park': {
                    name: 'Central Park',
                    title: 'Central Park Tours and Activities',
                    description: 'Explore the massive and beautiful Central Park in NYC.',
                    widgetRef: 'W-1be69fd2-9eca-423c-b13b-565cbde70ae6', 
                  },
                },
              },
            },
            attractions: {
              'niagara-falls': {
                name: 'Niagara Falls',
                title: 'Niagara Falls Tours and Activities',
                description: 'Experience the beauty and majesty of Niagara Falls.',
                bannerImage: '/images/banners/location-banners/niagara-falls-banner.jpeg',
                widgetRef: 'W-f2952980-ebfa-4f1e-b813-d14bedb32074',
              },
            },
          },
  
          //********* WASHINGTON, D.C. *****************/
          'dc': {
            name: 'Washington, D.C.',
            bannerImage: '/images/banners/location-banners/dc-banner.jpeg',
            attractions: {
              'capitol-hill': {
                name: 'Capitol Hill',
                title: 'Capitol Hill Tours and Activities',
                description: 'Explore Capitol Hill and the surrounding sights.',
                widgetRef: 'W-ecd5f9db-9f11-4e49-83f1-a6532f24fa5e',
              },
              'dc-monuments': {
                name: 'Washington, D.C. Monuments',
                title: 'Washington, D.C. Monuments Tours and Activities',
                description: 'Explore the many breathtaking monuments of Washington, D.C.',
                widgetRef: 'W-486fba22-1d31-425e-a599-d6c1bae5defc',
              },
              'dc-museums': {
                name: 'Washington, D.C. Museums',
                title: 'Washington, D.C. Museums Tours and Activities',
                description: 'Experience the incredible museums Washington, D.C. has to offer.',
                widgetRef: 'W-911e623c-ea1d-41f9-a29b-92413382f695',
              },
            },
          },
  
          //********* MASSACHUSETTS *****************/
          'massachusetts': {
            name: 'Massachusetts',
            bannerImage: '/images/banners/location-banners/boston-banner.jpeg',
            cities: {
              'boston': {
                name: 'Boston',
                attractions: {
                  'freedom-trail': {
                    name: 'Freedom Trail',
                    title: 'Freedom Trail Tours and Activities',
                    description: 'Walk the Freedom Trail and experience history.',
                    widgetRef: 'W-6ff72a44-2d1f-47d0-9cca-8de5a4a2619e',
                  },
                  'boston-museums': {
                    name: 'Boston Museums',
                    title: 'Boston Museums Tours and Activities',
                    description: 'See the rich history in Boston.',
                    widgetRef: 'W-b677ad75-b420-4d22-88e6-a8498aee499e',
                  },
                  'boston-harbor': {
                    name: 'Boston Harbor',
                    title: 'Boston Harbor Tours and Activities',
                    description: 'Get out on the water in the Boston Harbor.',
                    widgetRef: 'W-42b41d96-a8de-49ba-b4fa-300150e544fb',
                  },
                },
              },
            },
            attractions: {
              'salem': {
                name: 'Salem',
                title: 'Salem Tours and Activities',
                description: 'Visit the historical city of Salem.',
                bannerImage: '/images/banners/location-banners/salem-banner.jpg',
                widgetRef: 'W-7187e6f9-1b06-47ec-afd8-13bfcc05b2c8',
              },
            },
          },
        },
      },
  
      //**************************************************************************************/
      //*******************            SOUTH                 *********************************/
      //**************************************************************************************/
      'south': {
        name: 'South',
        bannerImage: '/images/banners/location-banners/miami-banner.jpeg', // Add a banner image if available
        states: {
          //********* FLORIDA *****************/
          'florida': {
            name: 'Florida',
            bannerImage: '/images/banners/state-banners/florida-banner.jpeg',
            attractions: {
              'miami': {
                name: 'Miami',
                title: 'Miami Tours and Activities',
                description: 'Enjoy the sunshine in beautiful Miami.',
                bannerImage: '/images/banners/location-banners/miami-banner.jpeg',
                widgetRef: 'W-e09abba9-5840-41d6-a783-ef2a9258b602',
              },
              'orlando': {
                name: 'Orlando',
                title: 'Orlando Tours and Activities',
                description: 'Ride an airboat, visit NASA, and anything in between.',
                widgetRef: 'W-4e1672a8-f8fd-4af2-b5eb-38f02dd3495a',
              },
              'key-west': {
                name: 'Key West',
                title: 'Key West Tours and Activities',
                description: 'Explore the southernmost point of the USA.',
                widgetRef: 'W-3cfa0ae1-9e7a-400b-9476-06e8cc6b4f3e',
              },
              'disney': {
                name: 'Walt Disney World Resort',
                title: 'Walt Disney World Resort',
                description: 'Experience Walt Disney World Resort!',
                widgetRef: 'W-a5599a91-0b8c-4a55-9fbb-c07eb6fd0184',
              },
            },
          },
  
          //********* LOUISIANA *****************/
          'louisiana': {
            name: 'Louisiana',
            bannerImage: '/images/banners/state-banners/louisiana-banner.jpeg',
            attractions: {
              'new-orleans': {
                name: 'New Orleans',
                title: 'New Orleans Tours and Activities',
                description: 'Experience the vibrant culture, music, and cuisine of New Orleans.',
                widgetRef: 'W-89b9eaa2-fb70-4232-8735-fbfe92b1c58b',
              },
              'lafayette': {
                name: 'Lafayette',
                title: 'Lafayette Tours and Activities',
                description:
                  'Immerse yourself in the heart of Cajun culture in Lafayette with vibrant music, authentic cuisine, and unforgettable swamp tours.',
                widgetRef: 'W-97038bb3-6f6e-4cf8-addd-33aafc7bd7b7',
              },
            },
          },
  
          //********* TENNESSEE *****************/
          'tennessee': {
            name: 'Tennessee',
            bannerImage: '/images/banners/location-banners/nashville-banner.jpeg',
            attractions: {
              'nashville': {
                name: 'Nashville',
                title: 'Nashville Tours and Activities',
                description: 'Experience the vibrant music scene, iconic honky-tonks, and rich cultural history of Nashville.',
                widgetRef: 'W-795728a1-56dd-4c8d-840a-879bd481380a',
              },
              'memphis': {
                name: 'Memphis',
                title: 'Memphis Tours and Activities',
                description: 'Discover the soulful charm of Memphis.',
                bannerImage: '/images/banners/location-banners/memphis-banner.jpg',
                widgetRef: 'W-ef55b756-72e2-443d-8e36-f6b554499f09',
              },
            },
          },
        },
      },
  
      //**************************************************************************************/
      //*******************            MIDWEST               *********************************/
      //**************************************************************************************/
      'midwest': {
        name: 'Midwest',
        bannerImage: '/images/regions/midwest.jpeg',
        states: {
          //********* OHIO **************/
          'ohio': {
            name: 'Ohio',
            bannerImage: '/images/banners/location-banners/cleveland-banner.jpeg',
            attractions: {
              'cincinnati': {
                name: 'Cincinnati',
                title: 'Cincinnati Tours & Experiences',
                description: 'Discover the charm of Cincinnati with its vibrant arts scene and historic architecture.',
                bannerImage: '/images/banners/location-banners/cincinnati-banner.jpeg',
                widgetRef: 'W-2e31f7c9-d25b-44d4-a18c-3d0be36e6034',
              },
              'cleveland': {
                name: 'Cleveland',
                title: 'Cleveland Tours & Experiences',
                description: 'Discover the vibrant cultural scene of Cleveland.',
                widgetRef: 'W-4e2d7756-61e9-4fe8-adee-fc402e1fd978',
              },
            },
          },
  
          //********* ILLINOIS **************/
          'illinois': {
            name: 'Illinois',
            bannerImage: '/images/banners/location-banners/chicago-banner.jpeg',
            attractions: {
              'chicago': {
                name: 'Chicago',
                title: 'Chicago Tours & Experiences',
                description: 'Discover the vibrant city of Chicago with its iconic architecture, world-class museums, and picturesque waterfront along Lake Michigan.',
                widgetRef: 'W-bb62d5d5-3c7f-42dc-aba8-290432bf9cc2',
              }
            }
          },
  
          //********* SOUTH DAKOTA **************/
          'south-dakota': {
            name: 'South Dakota',
            bannerImage: '/images/banners/state-banners/south-dakota-banner.jpeg',
            attractions: {
              'mount-rushmore': {
                name: 'Mount Rushmore & South Dakota Parks',
                title: 'Mount Rushmore & South Dakota Parks Tours',
                description: 'Discover the breathtaking landscapes and wildlife of South Dakota.',
                widgetRef: 'W-d3f30291-a613-4894-8e21-508c2576935a',
              },
            },
          },
        },
      },
  
      //**************************************************************************************/
      //*******************          MOUNTAIN WEST           *********************************/
      //**************************************************************************************/
  
      'mountain-west': {
        name: 'Mountain West',
        bannerImage: 'images/regions/mountain-west.jpeg',
        states: {
          //********* COLORADO **************/
          'colorado': {
            name: 'Colorado',
            bannerImage: '/images/banners/state-banners/colorado-banner.jpeg',
            attractions: {
              'boulder': {
                name: 'Boulder',
                title: 'Boulder Tours & Experiences',
                description: 'Immerse yourself in the charm of Boulder with its scenic mountain landscapes, vibrant arts scene, and outdoor adventure opportunities.',
                widgetRef: 'W-12c876b9-9866-4299-9e30-9c1146eebeed',
              },
              'colorado-springs': {
                name: 'Colorado Springs',
                title: 'Colorado Springs Tours & Experiences',
                description:'Discover the natural wonders of Colorado Springs, home to breathtaking red rock formations, scenic trails, and iconic landmarks.',
                widgetRef: 'W-fca71032-7bda-4a3f-81a4-39b5e7c6d355',
              },
              'denver': {
                name: 'Denver',
                title: 'Denver Tours & Experiences',
                description: 'Explore the Mile High City, where urban culture meets outdoor adventure.',
                widgetRef: 'W-983383cf-b294-45f2-9ed6-0c7a9e3d870a',
              },
            },
          },
  
          //********* UTAH **************/
          'utah': {
            name: 'Utah',
            bannerImage: '/images/banners/state-banners/utah-banner.jpeg',
            attractions: {
              'arches-national-park': {
                name: 'Arches National Park',
                title: 'Arches National Park Experiences',
                description: 'Marvel at the world’s largest concentration of natural stone arches, set against a stunning desert backdrop of red rock formations and towering spires.',
                widgetRef: 'W-6683efdc-3528-4b11-a8c0-7f6f748dbc94',
              },
              'bryce-canyon': {
                name: 'Bryce Canyon National Park',
                title: 'Bryce Canyon National Park Experiences',
                description: 'Explore a surreal landscape of vibrant red and orange hoodoos, unique rock formations, and panoramic views in this enchanting Utah wonder.',
                widgetRef: 'W-9742c409-1925-4c22-9645-77111de6d4f4',
              },
              'salt-lake-city': {
                name: 'Salt Lake City',
                title: 'Salt Lake City Tours & Experiences',
                description: '',
                widgetRef: 'W-2bbeddb6-071a-4249-bc4d-32e5de357d17',
              },
              
            },
          },
  
          //********* WYOMING **************/
          'wyoming': {
            name: 'Wyoming',
            bannerImage: '/images/banners/location-banners/yellowstone-banner.jpeg',
            attractions: {
              'yellowstone': {
                name: 'Yellowstone National Park',
                title: 'Yellowstone National Park Tours & Experiences',
                description: 'Experience the wonder of geothermal features, majestic wildlife, and stunning vistas in the world’s first national park',
                bannerImage: '/images/banners/location-banners/yellowstone-banner.jpeg',
                widgetRef: 'W-2dbe9bfe-b34e-453a-ad5b-a92ed6f916fa',
              },
              'wyoming-parks': {
                name: 'Other National Parks',
                title: 'National Parks of Wyoming Tours & Experiences',
                description: 'Discover breathtaking landscapes, abundant wildlife, and unparalleled natural beauty.',
                bannerImage: '/images/banners/location-banners/yellowstone-banner.jpeg',
                widgetRef: 'W-074d83f7-e385-41fb-b7fc-9f618f41babe',
              },
            },
          },
        },
      },
  
      //**************************************************************************************/
      //*******************           WEST COAST             *********************************/
      //**************************************************************************************/
      'west-coast': {
        name: 'West Coast',
        bannerImage: 'images/regions/west-coast',
        states: {
          //********* CALIFORNIA **************/
          'california': {
            name: 'California',
            bannerImage: 'images/regions/west-coast',
            cities: {
              'los-angeles': {
                name: 'Los Angeles',
                attractions: {
                  'hollywood': {
                    name: 'Hollywood',
                    title: 'Hollywood Tours & Experiences',
                    description: 'Explore the glitz and glamour of Hollywood, from the Walk of Fame to iconic film landmarks.',
                    bannerImage: '/images/banners/location-banners/hollywood-banner.jpeg',
                    widgetRef: 'W-41b98016-e71f-4032-8ec0-a4858a376643'
                  },
                  'la-art-culture': {
                    name: 'LA Art & Culture',
                    title: 'LA Art & Cultural Experiences',
                    description: 'Immerse yourself in the vibrant art scene, world-class museums, and cultural landmarks.',
                    bannerImage: '/images/banners/location-banners/hollywood-banner.jpeg',
                    widgetRef: 'W-13826937-30f2-4ffc-8a20-19ef5120de3c'
                  },
                  'malibu': {
                    name: 'Malibu',
                    title: 'Malibu Tours and Experiences',
                    description: 'Experience the serene beauty of pristine beaches, scenic coastline, and luxury lifestyle.',
                    bannerImage: '/images/banners/location-banners/hollywood-banner.jpeg',
                    widgetRef: 'W-0b5b66ac-fa70-4d63-85ac-f14a380c2f53'
                  },
                  'venice-beach': {
                    name: 'Venice Beach',
                    title: 'Venice Beach Tours and Experiences',
                    description: 'Discover the eclectic charm of Venice Beach with its iconic boardwalk, street performers, and vibrant culture.',
                    bannerImage: '/images/banners/location-banners/hollywood-banner.jpeg',
                    widgetRef: 'W-06dd1088-535a-434d-b1fa-548fb2b85391'
                  },
                },
              },
            },
            attractions: {
              'san-francisco': {
                name: 'San Francisco',
                title: 'San Francisco Tours & Experiences',
                description: 'Explore the iconic landmarks of San Francisco',
                bannerImage: '/images/regions/west-coast.jpeg',
                widgetRef: 'W-fee18982-ebdb-43d2-b38a-d9d17b7db471',
              },
              'lake-tahoe': {
                name: 'Lake Tahoe',
                title: 'Lake Tahoe Tours & Experiences',
                description: 'Bask in the natural beauty of Lake Tahoe, with crystal-clear waters and endless outdoor adventures.',
                bannerImage: '/images/banners/location-banners/tahoe-banner.jpeg',
                widgetRef: 'W-5455ce90-c6e4-4dc8-91fa-b7a910b95c40',
              }
            },
          },
  
          //********* NEVADA **************
          'nevada': {
            name: 'Nevada',
            bannerImage: '/images/banners/location-banners/vegas-banner.jpeg',
            attractions: {
              'las-vegas': {
                name: 'Las Vegas',
                title: 'Las Vegas Tours & Experiences',
                description: 'Experience the dazzling entertainment, vibrant nightlife, and iconic landmarks of the world-famous Las Vegas Strip.',
                bannerImage: '/images/banners/location-banners/vegas-banner.jpeg',
                widgetRef: 'W-89d1ad0a-26e1-4547-a4c5-c3c9fb3d35ce',
              },
              'hoover-dam': {
                name: 'Hoover Dam',
                title: 'Hoover Dam Tours & Experiences',
                description: 'Marvel at the engineering wonder of Hoover Dam and its stunning views of the Colorado River.',
                bannerImage: '/images/banners/location-banners/hoover-banner.jpeg',
                widgetRef: 'W-211d1ac8-b510-4b04-99db-41c6bb108f03',
              },
            }
          },
  
          //********* ARIZONA **************//
          'arizona': {
            name: 'Arizona',
            attractions: {
              'grand-canyon': {
                name: 'Grand Canyon',
                title: 'Grand Canyon Tours & Experiences',
                description: 'Be awed by the grandeur of the Grand Canyon, a natural wonder carved over millennia',
                bannerImage: '/images/banners/state-banners/arizona-banner.jpg',
                widgetRef: 'W-8318eb28-9b62-40a3-9efe-b773e2cf069a',
              },
              'sedona': {
                name: 'Sedona',
                title: 'Sedona Tours & Experiences',
                description: 'Unwind in the breathtaking red rock landscapes of Sedona, known for their beauty and spiritual energy',
                bannerImage: '/images/banners/state-banners/arizona-banner.jpg',
                widgetRef: 'W-632368ce-ec2b-4ccb-822b-f2007a318820',
              }
            }
          }
        },
      },
  
      //**************************************************************************************/
      //*******************        PACIFIC NORTHWEST         *********************************/
      //**************************************************************************************/
      'pacific-northwest': {
        name: 'Pacific Northwest',
        bannerImage: '/images/regions/pacific-northwest.jpeg',
        states: {
          //********* OREGON **************//
          'oregon': {
            name: 'Oregon',
            bannerImage: '/images/banners/state-banners/oregon-banner.jpeg',
            attractions: {
              'columbia-river-gorge': {
                name: 'Columbia River Gorge',
                title: 'Columbia River Gorge Tours & Experiences',
                description: 'Explore the awe-inspiring Columbia River Gorge.',
                widgetRef: 'W-cc31863c-0520-41f1-a4fe-fdb99bc2776b',
              },
  
              'portland': {
                name: 'Portland',
                title: 'Portland Tours & Experiences',
                description: 'Discover a vibrant food scene, unique culture, and lush parks',
                widgetRef: 'W-29db8137-d97d-4467-8e90-d6418b4d5d1b',
              },
            }
          },
          
          //********* WASHINGTON **************//
          'washington': {
            name: 'Washington',
            bannerImage: '/images/banners/state-banners/washington-banner.jpeg',
            attractions: {
              'seattle': {
                name: 'Seattle',
                title: 'Seattle Tours & Experiences',
                description: 'Experience the iconic Space Needle, bustling Pike Place Market, and stunning waterfront views.',
  
                widgetRef: 'W-230e580f-b5fe-4901-8167-f75d8caf0143',
              },
              'san-juan-islands': {
                name: 'San Juan Islands',
                title: 'San Juan Islands Tours & Experiences',
                description: 'Embark on an unforgettable journey to the San Juan Islands, where whale watching, kayaking, and quaint coastal towns await.',
                widgetRef: 'W-fbaed071-0866-4e05-9ca6-6f748aef1fef',
              },
            },
          },
        }
      },
  
      //**************************************************************************************/
      //*******************             HAWAII               *********************************/
      //**************************************************************************************/
      'hawaii': {
        name: 'Hawaii',
        bannerImage: '/images/regions/hawaii.jpeg',
        states: {
          'big-island': {
            name: 'Big Island',
            bannerImage: '/images/regions/hawaii.jpeg',
            attractions: {
              'mauna-kea': {
                name: 'Mauna Kea',
                title: 'Mauna Kea Tours & Experiences',
                description: 'Ascend the highest peak in Hawaii for incredible stargazing and cultural significance.',
                widgetRef: 'W-ebc97e3c-fe94-4c46-9cc0-4035a2384077',
              },
              'volcanoes-national-park': {
                name: 'Volcanoes National Park',
                title: 'Volcanoes National Park Tours & Experiences',
                description: 'Explore a dynamic realm of lava fields and fiery volcanic activity.',
                widgetRef: 'W-2e9e2d98-65b6-4dfc-a2c4-aebbe264b1d7'
              },
            },
          },
          'oahu': {
            name: 'Oahu',
            bannerImage: '/images/regions/hawaii.jpeg',
            attractions: {
              'honolulu': {
                name: 'Honolulu',
                title: 'Honolulu Tours & Experiences',
                description: 'Experience Hawaiis vibrant capital with pristine beaches, shopping, and nightlife.',
                widgetRef: 'W-78075545-be0f-4168-930b-83cda54ca643',
              },
              'pearl-harbor': {
                name: 'Pearl Harbor',
                title: 'Pearl Harbor Tours & Experiences',
                description: 'Pay tribute to history at this iconic World War II memorial site.',
                widgetRef: 'W-26e99709-4b58-478d-9632-2c4a15967b7a',
              },
              'north-shore': {
                name: 'North Shore',
                title: 'North Shore Tours & Experiences',
                description: 'Ride epic waves and embrace Oahus laid-back surf culture.',
                widgetRef: 'W-a4af90ee-0904-49b4-9d86-be63a7d0ac38',
              },
            },
          },
        },
      },
  
      //**************************************************************************************/
      //*******************             ALASKA               *********************************/
      //**************************************************************************************/
      'alaska': {
        name: 'Alaska',
        bannerImage: '/images/regions/alaska.jpg',
        states: {
          'cities': {
            name: 'Cities of Alaska',
            bannerImage: '/images/banners/state-banners/alaska-cities.jpg',
            attractions: {
              'anchorage': {
                name: 'Anchorage',
                title: 'Anchorage Tours & Experiences',
                description: 'Discover the biggest city in Alaska amid grand mountain vistas and coastal wildlife.',
                widgetRef: 'W-d9b2244d-7fd1-4851-b0a3-f3d06c3bc432',
              },
              'fairbanks': {
                name: 'Fairbanks',
                title: 'Fairbanks Tours & Experiences',
                description: 'Chase the northern lights and immerse yourself in the rugged interior of Alaska.',
                widgetRef: 'W-9c2f8536-fc82-46b8-a3bd-708c18638303'
              },
              'juneau': {
                name: 'Juneau',
                title: 'Juneau Tours & Experiences',
                description: 'Visit the Alaskan capital, known for glaciers, wildlife, and striking coastal scenery.',
                widgetRef: 'W-3aaebb2c-c866-4294-8291-4a35c0397681',
              }
            }
          },
          'nature': {
            name: 'Nature of Alaska',
            bannerImage: '/images/regions/alaska.jpg',
            attractions: {
              'kenai-fjords': {
                name: 'Kenai Fjords',
                title: 'Kenai Fjords Tours & Experiences',
                description: 'Cruise icy waters to witness calving glaciers and abundant marine life.',
                widgetRef: 'W-adca33a1-cc77-4c6f-8936-546338932319'
              },
              'denali-national-park': {
                name: 'Denali National Park',
                title: 'Denali National Park Tours & Experiences',
                description: 'Marvel at the tallest peak in North America and roam vast tundra landscapes.',
                widgetRef: 'W-1651cc61-3a8c-474e-9e54-8324aac14c86',
              }
            }
          }
        }
      }
      //**************************************************************************************/
      //*******************        OTHER USA REGIONS         *********************************/
      //**************************************************************************************/
    },
  },

  //**************************************************************************************/
  //*******************               ABROAD             *********************************/
  //**************************************************************************************/

  abroad: {
    name: 'Abroad',
    regions: {
      //**************************************************************************************/
      //*******************               EUROPE             *********************************/
      //**************************************************************************************/
      'europe': {
        name: 'Europe',
        bannerImage: '/images/banners/state-banners/france-banner.jpeg',
        states: {
          //********* ENGLAND **************//
          'england': {
            name: 'England',
            bannerImage: '/images/banners/state-banners/england-banner.jpeg',
            attractions: {
              'london': {
                name: 'London',
                title: 'London Tours & Experiences',
                description: 'Stroll past royal palaces and iconic museums.',
                widgetRef: 'W-eb87f3a8-d06b-490d-8029-50d16a421438',
              },
              'liverpool': {
                name: 'Liverpool',
                title: 'Liverpool Tours & Experiences',
                description: 'Relish musical heritage and waterfront charm in the home of The Beatles.',
                widgetRef: 'W-a479a176-51f3-4c64-9a84-47647892d269',
              },
            },
          },

          //********* ITALY **************//
          'italy': {
            name: 'Italy',
            bannerImage: '/images/banners/state-banners/italy-banner.jpeg',
            attractions: {
              'rome': {
                name: 'Rome',
                title: 'Rome Tours & Experiences',
                description: 'Explore the historic city of Rome, home to the Colosseum and Vatican City.',
                widgetRef: 'W-b5e14aba-521e-4cb6-902c-e0524e36196d',
              },
              'venice': {
                name: 'Venice',
                title: 'Venice Tours & Experiences',
                description: 'Glide along enchanting canals and admire timeless Renaissance art.',
                widgetRef: 'W-fb26a2cf-6002-48f8-b829-3dc29c1aa0a4'
              },
              'florence': {
                name: 'Florence',
                title: 'Florence Tours & Experiences',
                description: 'Bask in Renaissance masterpieces, historic bridges, and Tuscan panoramas.',
                widgetRef: 'W-a137218a-98e6-4105-bbb7-376f615df4e7',
              },
            },
          },
          //********* FRANCE **************//
          'france': {
            name: 'France',
            bannerImage: '/images/banners/state-banners/france-banner.jpeg',
            attractions: {
              'normandy': {
                name: 'Normandy',
                title: 'Normandy Tours & Experiences',
                description: 'Reflect on D-Day beaches and medieval towns along this scenic coast.',
                widgetRef: 'W-63111704-9309-4ca0-8a7c-508251440586',
              },
              'paris': {
                name: 'Paris',
                title: 'Paris Tours & Experiences',
                description: 'Indulge in art, fashion, and fine dining beneath the Eiffel Tower.',
                widgetRef: 'W-a36a9641-8b92-41f5-821a-5218461aee2f'
              },
              'bordeaux': {
                name: 'Bordeaux',
                title: 'Bordeaux Tours & Experiences',
                description: 'Savor world-renowned wines and elegant boulevards in southwestern France.',
                widgetRef: 'W-f2a57b52-9728-437c-8df9-54ef4e126c01',
              },
            }
          },
          //********* POLAND **************//
          'poland': {
            name: 'Poland',
            bannerImage: '/images/banners/state-banners/poland-banner.jpeg',
            attractions: {
              'krakow': {
                name: 'Krakow',
                title: 'Krakow Tours & Experiences',
                description: 'Wander a medieval core and discover Polands poignant World War II history.',
                widgetRef: 'W-dd99cbca-5413-47ce-bb91-4148ede8bbf9',
              },
              'warsaw': {
                name: 'Warsaw',
                title: 'Warsaw Tours & Experiences',
                description: 'Experience the dynamic capital of Poland, reborn from a resilient past.',
                widgetRef: 'W-a0b55393-379c-48be-845e-3aac4e0edbf3',
              },
            },
          },
          //********* SPAIN **************//
          'spain': {
            name: 'Spain',
            bannerImage: '/images/banners/state-banners/spain-banner.jpeg',
            attractions: {
              'barcelona': {
                name: 'Barcelona',
                title: 'Barcelona Tours & Experiences',
                description: 'Admire the architecture, tapas, and Mediterranean energy.',
                widgetRef: 'W-69d1f90f-c92c-4ade-9c94-51c0e0f2b11e',
              },
              'madrid': {
                name: 'Madrid',
                title: 'Madrid Tours & Experiences',
                description: 'Enjoy grand boulevards, art museums, and lively plazas.',
                widgetRef: 'W-109cd8ff-f917-431a-beaa-12b400cf27bd',
              },
            },
          },
          //********* ICELAND **************//
          'iceland': {
            name: 'Iceland',
            bannerImage: '/images/banners/state-banners/iceland-banner.jpeg',
            attractions: {
              'reykjavik': {
                name: 'Reykjavik',
                title: 'Reykjavik Tours & Experiences',
                description: 'Explore the colorful capital of Iceland and soak in geothermal wonders.',
                widgetRef: 'W-603f3974-b4e7-4775-bbc1-27b53ee5f8b6',
              },
            },
          },
        },
      },

      //**************************************************************************************/
      //*******************           MIDDLE EAST            *********************************/
      //**************************************************************************************/

      'middle-east': {
        name: 'Middle East',
        bannerImage: '/images/banners/state-banners/dubai-banner.jpeg',
        states: {
          //********* EGYPT **************//
          'egypt': {
            name: 'Egypt',
            bannerImage: '/images/banners/state-banners/egypt-banner.jpeg',
            attractions: {
              'giza': {
                name: 'Giza Pyramids',
                title: 'Giza Pyramids Tours & Experiences',
                description: 'Gaze upon ancient pyramids rising from desert sands.',
                widgetRef: 'W-327c9d62-da28-44e8-80c9-2e69f650329b',
              },
            },
          },
          //********* UNITED ARAB EMIRATES **************//
          'uae': {
            name: 'United Arab Emirates',
            bannerImage: '/images/banners/state-banners/dubai-banner.jpeg',
            attractions: {
              'dubai': {
                name: 'Dubai',
                title: 'Dubai Tours & Experiences',
                description: 'Marvel at futuristic skyscrapers, luxury shopping, and desert thrills in the UAE.',
                widgetRef: 'W-6b6bccba-541a-415b-b1fd-8baddffb616b'
              },
              'abu-dhabi': {
                name: 'Abu Dhabi',
                title: 'Abu Dhabi Tours & Experiences',
                description: 'Discover the cultural jewel of the UAE with majestic mosques and modern attractions.',
                widgetRef: 'W-27946c81-dd7f-4032-8d71-2928e23676bf',
              },
            },
          },
          //********* QATAR **************//
          'qatar': {
            name: 'Qatar',
            bannerImage: '/images/banners/state-banners/qatar-banner.jpeg',
            attractions: {
              'doha': {
                name: 'Doha',
                title: 'Doha Tours & Experiences',
                description: 'Immerse yourself in sleek architecture, waterfront promenades, and vibrant souqs.',
                widgetRef: 'W-2725e33c-63d4-4b6e-ab67-6217ecbf7fc6',
              },
            },
          },
        },
      },

      //**************************************************************************************/
      //*******************          NORTH AMERICA           *********************************/
      //**************************************************************************************/

      'north-america': {
        name: 'North America',
        bannerImage: '/images/banners/state-banners/mexico-banner.jpeg',
        states: {
          //********* CANADA **************//
          'canada': {
            name: 'Canada',
            bannerImage: '/images/banners/state-banners/canada-banner.jpeg',
            attractions: {
              'montreal': {
                name: 'Montreal',
                title: 'Montreal Tours & Experiences',
                description: 'Experience French-inspired charm, lively festivals, and multicultural flair in eastern Canada.',
                widgetRef: 'W-52de5406-49a7-4cd5-9ab5-805bc9849f01',
              },
              'toronto': {
                name: 'Toronto',
                title: 'Toronto Tours & Experiences',
                description: 'Explore the largest city in Canada for global cuisine, sky-high towers, and cultural gems.',
                widgetRef: 'W-f46ddd3a-178c-4780-8893-06ddeddc2dff'
              },
            },
          },
          //********* MEXICO **************//
          'mexico': {
            name: 'Mexico',
            bannerImage: '/images/banners/state-banners/mexico-banner.jpeg',
            attractions: {
              'mexico-city': {
                name: 'Mexico City',
                title: 'Mexico City Tours & Experiences',
                description: 'Dive into a mega-city of ancient ruins, cutting-edge art, and mouthwatering street food.',
                widgetRef: 'W-67ab80f9-45a7-47af-b5f7-754480e26f69',
              },
              'oaxaca': {
                name: 'Oaxaca',
                title: 'Oaxaca Tours & Experiences',
                description: 'Revel in vivid markets, regional gastronomy, and rich artistic heritage in southern Mexico.',
                widgetRef: 'W-2b11c620-0a18-4f95-a221-ef2243416381',
              },
              'tulum': {
                name: 'Tulum',
                title: 'Tulum Tours & Experiences',
                description: 'Discover ancient Maya ruins and turquoise beaches along the Mexican Caribbean.',
                widgetRef: 'W-b9a02714-80c0-413b-af10-ba9f1298b05f',
              },
            },
          },
        },
      },

      //**************************************************************************************/
      //*******************          SOUTH AMERICA           *********************************/
      //**************************************************************************************/
      'south-america': {
        name: 'South America',
        bannerImage: '/images/banners/state-banners/brazil-banner.jpeg',
        states: {
          //********* ARGENTINA **************//
          'argentina': {
            name: 'Argentina',
            bannerImage: '/images/banners/state-banners/argentina-banner.jpeg',
            attractions: {
              'buenos-aires': {
                name: 'Buenos Aires',
                title: 'Buenos Aires Tours & Experiences',
                description: 'Indulge in tango rhythms, European-style boulevards, and flavorful cuisine in Argentinas capital.',
                widgetRef: 'W-20b040f5-8425-4342-8267-3d4d6ad906be',
              },
              'mendoza': {
                name: 'Mendoza',
                title: 'Mendoza Tours & Experiences',
                description: 'Savor world-class Malbec wines against a stunning backdrop of Andean peaks.',
                widgetRef: 'W-69d1f90f-c92c-4ade-9c94-51c0e0f2b11e',
              },
            },
          },
          //********* BRAZIL **************//
          'brazil': {
            name: 'Brazil',
            bannerImage: '/images/banners/state-banners/brazil-banner.jpeg',
            attractions: {
              'manaus': {
                name: 'Manaus',
                title: 'Manaus Tours & Experiences',
                description: 'Venture deep into the Amazon and marvel at diverse wildlife in the rainforest gateway.',
                widgetRef: 'W-d571be24-ef09-4bc9-b2d2-e8e9b7e85ee7',
              },
              'rio-de-janeiro': {
                name: 'Rio De Janeiro',
                title: 'Rio De Janeiro Tours & Experiences',
                description: 'Bask in iconic beaches, vibrant samba, and panoramic views at Sugarloaf Mountain.',
                widgetRef: 'W-3cc13c02-5560-4112-85f4-d18c258943c6',
              },
            },
          },
          //********* URUGUAY **************//
          'uruguay': {
            name: 'Uruguay',
            bannerImage: '/images/banners/state-banners/uruguay-banner.jpeg',
            attractions: {
              'punta-del-este': {
                name: 'Punta del Este',
                title: 'Punta del Este Tours & Experiences',
                description: 'Unwind amid glamorous beachfront resorts and lively nightlife.',
                widgetRef: 'W-5b056f4d-17bb-4716-a357-1debb58706ae',
              },
              'montevideo': {
                name: 'Montevideo',
                title: 'Montevideo Tours & Experiences',
                description: 'Embrace a fusion of colonial charm and modern energy in the spirited capital of Uruguay.',
                widgetRef: 'W-5f59c436-c58c-4fb5-a7c7-9330a9f96be2',
              },
            },
          },
        },
      },
    },
  }
};
  

export default productData;
