// src/components/Navbar.js

import React, { useEffect, useState } from 'react';
import '../styles/Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import productData from '../data/productData';

const Navbar = () => {
  const [isShrunk, setIsShrunk] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSubmenus, setOpenSubmenus] = useState({});

  useEffect(() => {
    const handleScroll = () => setIsShrunk(window.scrollY > 50);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen) {
      setOpenSubmenus({});
    }
  };

  const toggleSubmenu = (keyPath) => {
    setOpenSubmenus((prev) => ({
      ...prev,
      [keyPath]: !prev[keyPath],
    }));
  };

  // Helper to gather any sub-items (whether states, cities, or attractions).
  const getAllSubItems = (item) => {
    const subItems = {};
    if (item?.states) {
      Object.entries(item.states).forEach(([k, v]) => { subItems[k] = v; });
    }
    if (item?.cities) {
      Object.entries(item.cities).forEach(([k, v]) => { subItems[k] = v; });
    }
    if (item?.attractions) {
      Object.entries(item.attractions).forEach(([k, v]) => { subItems[k] = v; });
    }
    return subItems;
  };

  // Renders any portion of productData, building URLs and submenus.
  // `pathSegments` is an array of strings that becomes the URL path.
  const renderMenuItems = (data, pathSegments = [], isMobile = false) => {
    if (!data) return null;

    return (
      <ul className="menu-level">
        {Object.keys(data).map((key) => {
          const item = data[key];
          // The URL includes the prefix (e.g., 'usa' or 'abroad') plus the current key
          const url = `/${[...pathSegments, key].join('/')}`;
          const keyPath = url;
          const displayName = item?.name || key;

          const subItems = getAllSubItems(item);
          const hasSubItems = Object.keys(subItems).length > 0;

          if (hasSubItems) {
            // This item has deeper sub-levels
            return (
              <li
                key={keyPath}
                className={`menu-item ${isMobile && openSubmenus[keyPath] ? 'open' : ''}`}
              >
                <div
                  className="menu-link"
                  onClick={isMobile ? () => toggleSubmenu(keyPath) : undefined}
                >
                  {displayName}
                  <FontAwesomeIcon
                    icon={
                      isMobile
                        ? openSubmenus[keyPath]
                          ? faChevronUp
                          : faChevronDown
                        : faChevronRight
                    }
                    className="menu-icon"
                  />
                </div>
                {(isMobile ? openSubmenus[keyPath] : true) && (
                  <div className="submenu">
                    {renderMenuItems(subItems, [...pathSegments, key], isMobile)}
                  </div>
                )}
              </li>
            );
          } else {
            // Leaf node (final)
            return (
              <li key={keyPath} className="menu-item">
                <a
                  key={url}
                  href={url}
                  className="menu-link"
                  onClick={() => isMobile && setIsMenuOpen(false)}
                >
                  {displayName}
                </a>
              </li>
            );
          }
        })}
      </ul>
    );
  };

  return (
    <>
      <nav className={`navbar ${isShrunk ? 'shrunk' : ''}`}>
        <div className="navbar-container">
          {/* Logo and Home Link */}
          <div className="navbar-left">
            <a href="/" className="logo-link">
              <div className="logo-container">
                <img
                  src="/images/isightseeing.png"
                  alt="Logo"
                  className="logo-image"
                />
              </div>
            </a>
            <a href="/" className="nav-link desktop-only">
              Home
            </a>

            {/* USA Dropdown (Desktop) */}
            <div className="navbar-item desktop-only">
              <div className="nav-link cursor-pointer">
                USA
                <FontAwesomeIcon icon={faChevronDown} className="nav-icon" />
              </div>
              <div className="navbar-dropdown">
                {/*
                  Pass ["usa"] as the initial pathSegments array.
                  That means everything under productData.usa.regions
                  will produce URLs that start with /usa/...
                */}
                {renderMenuItems(productData.usa?.regions, ['usa'])}
              </div>
            </div>

            {/* Abroad Dropdown (Desktop) */}
            <div className="navbar-item desktop-only">
              <div className="nav-link cursor-pointer">
                Abroad
                <FontAwesomeIcon icon={faChevronDown} className="nav-icon" />
              </div>
              <div className="navbar-dropdown">
                {/*
                  Pass ["abroad"] as the initial pathSegments array.
                  So everything under productData.abroad.regions
                  creates URLs like /abroad/europe/italy/rome, etc.
                */}
                {renderMenuItems(productData.abroad?.regions, ['abroad'])}
              </div>
            </div>
          </div>

          {/* Hamburger Icon (Mobile) */}
          <div className="mobile-only">
            <div
              className={`hamburger-icon ${isMenuOpen ? 'open' : ''}`}
              onClick={toggleMenu}
            >
              <div className="icon-bar"></div>
              <div className="icon-bar"></div>
              <div className="icon-bar"></div>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className={`mobile-menu ${isMenuOpen ? 'slide-in' : 'slide-out'}`}>
          <div className="mobile-menu-content">
            <a href="/" className="menu-link" onClick={() => setIsMenuOpen(false)}>
              Home
            </a>
            {/* 
              Show USA & Abroad in mobile as well 
              (with the same prefix arrays).
            */}
            {renderMenuItems(productData.usa?.regions, ['usa'], true)}
            {renderMenuItems(productData.abroad?.regions, ['abroad'], true)}
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
